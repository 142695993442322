import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../model/apiresponse.model';
import { environment } from 'src/environments/environment';
import { User } from '../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  profile(): Observable<ApiResponse<User | null>> {
    return this.http.get<ApiResponse<User | null>>(
      `${environment.apiUrl}/profile`
    );
  }

  updateProfile(name: string, lastName: string): Observable<ApiResponse<User | null>> {
    return this.http.put<ApiResponse<User | null>>(
      `${environment.apiUrl}/profile`,
      {
        name: name,
        last_name: lastName
      }
    );
  }

  enable2FA(type: string): Observable<ApiResponse<User | null>> {
    return this.http.put<ApiResponse<User | null>>(
      `${environment.apiUrl}/profile`,
      {
        mfa_enabled: true,
        mfa_type: type
      }
    );
  }

  disable2FA(): Observable<ApiResponse<User | null>> {
    return this.http.put<ApiResponse<User | null>>(
      `${environment.apiUrl}/profile`,
      {
        mfa_enabled: false
      }
    );
  }

  getProfileImage(): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/profile/picture`,
      {
        headers: new HttpHeaders({'Accept': 'image/png,image/jpg'}),
        responseType: 'blob'
      }
    );
  }

  uploadProfileImage(imageFile: Blob): Observable<ApiResponse<any>> {
    const formData = new FormData();
    formData.append('picture', imageFile);

    return this.http.post<any>(
      `${environment.apiUrl}/profile/picture`,
      formData
    );
  }

  getQRCodeTOTP(): Observable<Blob> {
    return this.http.get(
      `${environment.apiUrl}/profile/qr`,
      {
        headers: new HttpHeaders({'Accept': 'image/png'}),
        responseType: 'blob'
      }
    );
  }

  getTOTPSecret(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/profile/totp-secret`
    );
  }
}
